import AutoNumeric from 'autonumeric';
import Rails from "@rails/ujs"
import 'bootstrap/js/dist/tab';
require('twitter-bootstrap-wizard')
// require("select2")
// require("select2/dist/css/select2")
import I18n from 'i18n-js/index.js.erb'
require("gasparesganga-jquery-loading-overlay")
import { setupSelectEvents} from "src/address_selector/utils"
import validate from 'jquery-validation'
if (document.documentElement.lang === "zh-HK") {
  require("jquery-validation/dist/localization/messages_zh_TW")
}
// require("iframe-resizer")
import 'bootstrap/js/dist/modal';
require("datatables.net")
require('datatables.net-bs4')
require("datatables.net-select-dt")
require("datatables.net-responsive")
require("datatables.net-responsive-bs4")
import gtag, { install } from 'ga-gtag';
install('G-VZZCC8MLD1');


function replaceAll(str, find, replace) {
	return str.replace(new RegExp(find, 'g'), replace);
}

/*-----------------------------------------------
|   Jquery Validation
-----------------------------------------------*/
function hkidCheck(id) {
  if (/^[A-Z]{1,2}[0-9]{6}\([0-9,A]\)$/g.test(id)) {
    if (id.length == 10) {
      id = " " + id;
    }

    var i;
    var sum = 0;
    var reminder;
    var trimmed_id = id.replace(id.substring(id.length-3, id.length), "")
   
    for(i = 0;i < 8;i++) {
      sum += hkidValue(trimmed_id[i]) * (9 - i)
    }

    reminder = sum % 11
    
    if (reminder == 0) {
      return parseInt(id[9]) == 0
    } else if (reminder == 1) {
      return id[9] == 'A'
    } else {
      return (11-reminder) == parseInt(id[9])
    }
  }
  return false; 
}

function hkidValue(char) {
  if (char == ' ') {
    return 36
  } else if (/^[A-Z]$/g.test(char)) {
    return char.charCodeAt(0) - 55
  } else {
    return parseInt(char)
  }
}

document.addEventListener("turbolinks:load", function() {
  I18n.locale = $('body').data('locale');
  var forms = $('.form-validation');
  var hkid_error_msg = "HKID is invalid: Please enter a valid HKID, e.g. Z683365(5).";
  if (document.documentElement.lang == "zh-HK") {
    hkid_error_msg = "香港身份証號格式不正確：請輸入正確的身份格式, 例如： Z683365(5)";
  }
 
  // HKID validation rule
  $.validator.addMethod("hkid", function(value, element) {
      // var hkid_without_bracket = value.replace("(", "").replace(")", "");
      return this.optional(element) || hkidCheck(value);
  }, hkid_error_msg);

  forms.length && forms.each(function (index, value) {
    var $this = $(value);
    
    var options = $.extend({
      ignore: ".jvalidate-ignore",
      errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) { 
            error.insertAfter(element.parent());      // radio/checkbox?
        } else if (element.hasClass('select2-hidden-accessible')) {     
            error.insertAfter(element.next('span'));  // select2
        } else {                                      
            error.insertAfter(element);               // default
        }
      }

    }, $this.data('options'));
    $this.validate(options);
  });

});

/*-----------------------------------------------
|   Bootstrap Wizard
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selector = {
    DATA_WIZARD: '[data-wizard-card]',
    PREVIOUS_BUTTON: '.previous .btn',
    TAB_PANE: '.tab-pane',
    FORM_VALIDATION: '.form-validation',
    NAV_ITEM_CIRCLE: '.nav-item-circle',
    NAV_ITEM: '.nav-item',
    NAV_LINK: '.nav-link',
    WIZARD_LOTTIE: '.wizard-lottie'
  };
  var ClassName = {
    ACTIVE: 'active',
    DONE: 'done',
    NAV: 'nav'
  };
  var DATA_KEY = {
    OPTIONS: 'options',
    WIZARD_STATE: 'wizard-state',
    CONTROLLER: 'controller',
    ERROR_MODAL: 'error-modal'
  };
  var wizards = $(Selector.DATA_WIZARD);

  
  var isFormValidate = function isFormValidate($currentTab) {
    var $currentTabForms = $currentTab.find(Selector.FORM_VALIDATION);
    var isValidate = true;
    if ($currentTab[0].id == "connect_card_tab") {
      var iframe = document.querySelector('iframe[id="saledge_connect_frame"]');
      var form = iframe.contentWindow.document.querySelector('form#update_card_information_form');
      var selected_radio_button = iframe.contentWindow.document.querySelector('input[name="card_instalment[account_id]"]:checked');
      var j_selected_radio_button = $(selected_radio_button);
      if (j_selected_radio_button.length > 0 && j_selected_radio_button.attr("avaliable_loan_amount") > 2000 && $(form).valid()) {
        isValidate = true;
      } else {
        isValidate = false;
        // $("#card_avaliable_amount_alert").show();
        $('#card_avaliable_amount_alert').modal("show");
        // alert(I18n.t("card_instalment.application_steps.select_card.select_avaliable_card_reminder"))
      }
      
    }
    $currentTabForms.each(function (i, v) {
      isValidate = $(v).valid();

      return isValidate;
    });
    return isValidate;
  };

  var submitForm = (currentTab) => {
  	switch (currentTab.attr("id")) {
		case "connect_card_tab":
      var iframe = document.querySelector('iframe[id="saledge_connect_frame"]');
      var form = iframe.contentWindow.document.querySelector('form#update_card_information_form');
      Rails.fire(form, 'submit');
      $.LoadingOverlay("show");
      
      break;
		case "select_transactions_tab":
      var dt_obj = $('#select_transaction_table').dataTable(); 
      var selected_data = dt_obj.api().rows({selected: true }).data();
      var selected_transaction_payload = [];
      selected_data.each( function ( value, index ) {
          console.log( 'Data in index: '+index+' is: '+value );
          selected_transaction_payload.push(value)
      } ); 
      Rails.ajax({
        url: "/card_instalments/select_transactions",
        type: "post",
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          // Workaround: add options.data late to avoid Content-Type header to already being set in stone
          // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
          options.data = JSON.stringify({selected_transactions: selected_transaction_payload, card_instalment_id: $("#card_instalment_id").val()})
          $.LoadingOverlay("show");
          return true
        },
        success: function(data) {
          console.log(`select_transaction success: ${data}`)
          $("#instalment_card_number").val(data['card_number']);
          $("#instalment_transaction_total_amount").val(data['requested_amount']);
          $("#monthly_interest").val(data['interest_rate']);
          $("#eir").val(data['eir']);
          $("#monthly_repayment_amount").val(data['monthly_instalment']);
          $("#total_interest").val(data['total_interest']);
          $.LoadingOverlay("hide");

        },
        error: function(data) {
          console.log(`select_transaction error: ${data}`)
          $.LoadingOverlay("hide");
        }
      })
      break;
    case "personal_information_tab":
      var form = document.querySelector('form#card_instalment_addrvalidation_form');
      Rails.fire(form, 'submit');
      // var form = document.querySelector('form#update_card_instalment_personal_information_form');
      // Rails.fire(form, 'submit');
      break;
		default:
			// statements_def
			break;
	}
  }

  !!wizards.length && wizards.each(function (index, value) {
    var $this = $(value);
    var controller = $this.data(DATA_KEY.CONTROLLER);
    var $controller = $(controller);
    var $buttonPrev = $controller.find(Selector.PREVIOUS_BUTTON);
    var $modal = $($this.data(DATA_KEY.ERROR_MODAL));
    var $lottie = $(value).find(Selector.WIZARD_LOTTIE);
    var options = $.extend({
      container: value.querySelector(Selector.WIZARD_LOTTIE),
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: 'Hello World'
    }, $lottie.data(DATA_KEY.OPTIONS));
    var animation = window.bodymovin.loadAnimation(options);
    $this.bootstrapWizard({
      tabClass: ClassName.NAV,
      onNext: function onNext(tab, navigation, idx) {
        var $currentTab = $this.find(Selector.TAB_PANE).eq(idx - 1);
        // var $currentTab = tab;
        if (isFormValidate($currentTab)) {
        	submitForm($currentTab)
        	return true
        } else {
        	return false;
        }
        
      },
      onPrevious: function onPrevious(tab, navigation, idx) {
        var $currentTab = $this.find(Selector.TAB_PANE).eq(idx);
        // var $currentTab = tab;
        if ($currentTab.attr("id") == "connect_card_tab") {
          var dataTables = $('#select_transaction_table');
          if (dataTables.length !== 0) {
            dataTables.DataTable().destroy();
          }
          $("#application_form_next_btn").prop("disabled", false);
        }
        return true
        
      },
      onTabClick: function onTabClick(tab, navigation, idx, clickedIndex) {
        return false
        var stepDone = $this.find(".nav-item:nth-child(" + (clickedIndex + 1) + ") .nav-link").data(DATA_KEY.WIZARD_STATE);
        if (clickedIndex > idx) {
          return false
        }
        // return true
        if (stepDone === 'done') {
          $modal.modal('show');
          return false;
        }

        // if (clickedIndex <= idx) {
        //   return true;
        // }

        var isValid = true;
        $this.find(Selector.TAB_PANE).each(function (tabIndex, tabValue) {
          if (tabIndex < clickedIndex) {
            $this.bootstrapWizard('show', tabIndex);
            isValid = isFormValidate($(tabValue));
          }

          return isValid;
        });
        return isValid;
      },
      onTabShow: function onTabShow(tab, navigation, idx) {
        var length = navigation.find('li').length - 1;
        idx === 0 ? $buttonPrev.hide() : $buttonPrev.show();
        idx === length && setTimeout(function () {

          return animation.play();
        }, 300);
        $this.find(Selector.NAV_LINK).removeClass(ClassName.DONE);
        $this.find(Selector.NAV_ITEM).each(function (i, v) {
          var link = $(v).find(Selector.NAV_LINK);

          // Application Review Page
          if (idx === length && !link.hasClass(ClassName.ACTIVE)) {
            link.attr('data-wizard-state', 'done');
            
            
          }

          if (!link.hasClass(ClassName.ACTIVE)) {
            link.addClass(ClassName.DONE);
            return true;
          }

          if (idx === length) {
            link.addClass(ClassName.DONE);
            $controller.hide();
          }

          if (idx < length) {
            $controller.show();
            window.scrollTo(0, 0);
          }


          return false;
        });
      }
    });
  });
});


const select_transactions = () => {
  var checkbox_selector = ".transaction_checkbox";
  var checkboxes = document.querySelector(checkbox_selector);

};

// select tenor 
document.addEventListener("turbolinks:before-cache", function () {
  if ($('.selectpicker-tenor').first().data('select2') != undefined) $('.selectpicker-tenor').select2('destroy');
});

document.addEventListener("turbolinks:load", function() {
  var select2 = $('.selectpicker-tenor');
  select2.length && select2.each(function (index, value) {
    var $this = $(value);
    var options = $.extend({
      theme: 'bootstrap4'
    }, $this.data('options'));
    $this.select2(options);
  });

  $('#update_tenor_select').on('select2:select', function() {
    var form = document.querySelector('form#update_tenor_form');
    Rails.fire(form, 'submit');
  });

  $("#update_tenor_form").on("ajax:success", (event) => {
      console.log("success")
      var data = event.detail[0]
      $("#instalment_card_number").val(data['card_number']);
      $("#instalment_transaction_total_amount").val(data['requested_amount']);
      $("#monthly_interest").val(data['interest_rate']);
      $("#eir").val(data['eir']);
      $("#monthly_repayment_amount").val(data['monthly_instalment']);
      $("#total_interest").val(data['total_interest']);
      gtag('event', 'card_instalment_tenor_selected', 
          {
            "required_amount": data['requested_amount']
          });
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });

  $("#card_instalment_addrvalidation_form").on("ajax:success", (event) => {
      console.log("success")
      console.log(event.detail[0])
      $("#card_instalment_residential_address").val(event.detail[0])
      $("#address_ekyc_container #address_field").val(event.detail[0])
      var form = document.querySelector('form#update_card_instalment_personal_information_form');
      // gtag('event', 'update_address_information', {"user_id": $("input[name='user_id']").val()});
      Rails.fire(form, 'submit');
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });

  $("#update_card_instalment_personal_information_form").on("ajax:success", (event) => {
      console.log("success")
      console.log(event.detail[0])
      var hkid_no = event.detail[0]['hkid_no']
      var name = `${event.detail[0]['last_name']} ${event.detail[0]['first_name']}`
      $("#hkid_field").val(hkid_no)
      $("#address_ekyc_container #name_field").val(name)
      // var form = document.querySelector('form#update_card_instalment_personal_information_form');
      // // gtag('event', 'update_address_information', {"user_id": $("input[name='user_id']").val()});
      // Rails.fire(form, 'submit');
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });
});

document.addEventListener("turbolinks:load", function() {
  
  var iframe_height = 0;
  var over_limit_alert_shown = false
  var getSelectedCardAvaliableAmount = () => {
    var iframe = document.querySelector('iframe[id="saledge_connect_frame"]');
    var form = iframe.contentWindow.document.querySelector('form#update_card_information_form');
    var selected_radio_button = iframe.contentWindow.document.querySelector('input[name="card_instalment[account_id]"]:checked');
    if (selected_radio_button) {
      var j_selected_radio_button = $(selected_radio_button);
      return j_selected_radio_button.attr("avaliable_loan_amount")
    } else {
      return null
    }
  }
  var connect_card_container = document.querySelector('div[id="connect_card_container"]');
  if (connect_card_container) {
  var iframe = document.createElement('iframe');
  iframe.src = "/card_instalments/bank_list_page/?card_instalment_id=" + $("#card_instalment_id").val();
  // iframe.src = "/card_instalments/select_card?card_instalment_id=" + $("#card_instalment_id").val();
  iframe.class = "embed-responsive-item";
  iframe.id = "saledge_connect_frame";
  iframe.frameBorder = "0";
  iframe.width = "100%"
  iframe.minHeight = "596px"
  iframe.style = "min-height: '596px';"
  // iframe.scrolling="no"
  // iframe.onload = "";
  // document.querySelector('iframe[id="saledge_connect_frame"]');
  const sendPostMessage = () => {
      try {
        var body = iframe.contentWindow.document.body, html = iframe.contentWindow.document.documentElement;
        var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
        $("#saledge_connect_frame").css("height", height);
      }
      catch(err) {
        $("#saledge_connect_frame").css("height", "600px");
      }

      
    }
  iframe.onload = function() {
    sendPostMessage();
    var iframe = document.querySelector('iframe[id="saledge_connect_frame"]');
    var form = iframe.contentWindow.document.querySelector('form#update_card_information_form');
    var form_j = $(form);
    var options = $.extend({
      ignore: ".jvalidate-ignore",
      errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) { 
          error.insertAfter(element.parent());      // radio/checkbox?
        } else if (element.hasClass('select2-hidden-accessible')) {     
          error.insertAfter(element.next('span'));  // select2
        } else if (element.attr('type') == "radio") {
          error.insertBefore(element.parent().parent().parent());  
        } else {                                      
          error.insertAfter(element);               // default
        }
      }
    }, form_j.data('options'));
    form_j.validate(options);
    $('#saltedge_connect_button').contents().find("#update_card_information_form").on("ajax:success", (event) => {
      sendPostMessage();
    });
    $('#saledge_connect_frame').contents().find("#update_card_information_form").on("ajax:success", (event) => {
      $.LoadingOverlay("hide");
      gtag('event', 'card_instalment_saltedge_connected', 
          {});
      $("#application_form_next_btn").prop("disabled", true);

      var dtable = $('#select_transaction_table');

      var data = event.detail[0];
      $("#select_transactions_container #card_instalment_id").val(data['card_instalment']['id'])
      $("#update_tenor_form #card_instalment_id").val(data['card_instalment']['id'])
      $("#submit_card_instalments_form #card_instalment_id").val(data['card_instalment']['id'])
      var customDataTable = function customDataTable(elem) {
        elem.find('.pagination').addClass('pagination-sm');
      };
        var options = $.extend({
          data: data['transactions'],
          columnDefs: [ 
              {
                orderable: false,
                className: 'select-checkbox',
                targets:   0,
              },   
              {
                targets: [4],
                visible: false 
              }
            ],
            select: {
                style:    'multi',
                selector: 'td:first-child'
            },
            // select: true,
            order: [[ 1, 'asc' ], [2, 'asc'], [3, 'asc']],
          // responsive: true,
          dom:"<'row mx-1'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'table-responsive'tr><'row no-gutters px-1 py-3 align-items-center justify-content-center'<'col-auto'p>>"
        }, dtable.data('options'));
        var dt_obj = dtable.DataTable(options);
        var $wrpper = dtable.closest('.dataTables_wrapper');
        customDataTable($wrpper);

        const update_selected_amount_fn = (e, dt, type, indexes) => {
          var selected_rows = dt.rows( { selected: true } )
          var selected_data = selected_rows.data();
          var sum_amount = 0;
          selected_data.each( function ( value, index ) {
            console.log( 'Data in index: '+index+' is: '+value );
            var amount_str = value[3];
            var amount = parseFloat(amount_str.replace("HK$", "").replace(",", "").replace(/ /g, ""))
            sum_amount = sum_amount + amount
          } ); 
          var selected_amount = $("#select_transaction-selected_amount").html(`HK$ ${Math.round((sum_amount + Number.EPSILON) * 100) / 100}`);
          var avaliable_amount = getSelectedCardAvaliableAmount();
           if (sum_amount >= 1 && avaliable_amount > sum_amount) {
             $("#application_form_next_btn").prop("disabled", false);
           } else {
             if (! over_limit_alert_shown) {
               alert(I18n.t("card_instalment.application_steps.select_transaction.selected_transaction_amount_exceed", {selected_transaction_amount: sum_amount, eligible_loan_amount: Math.round((avaliable_amount + Number.EPSILON) * 100) / 100}))
               over_limit_alert_shown = true
             }
             $("#application_form_next_btn").prop("disabled", true);
           }
        }
        dt_obj.on('select', function ( e, dt, type, indexes) {
          update_selected_amount_fn(e, dt, type, indexes);
        });
        dt_obj.on('deselect', function ( e, dt, type, indexes ) {
          update_selected_amount_fn(e, dt, type, indexes);
        });

        dtable.on('draw.dt', function () {
          console.log("draw.dt");
          return customDataTable($wrpper);
        });

          // $("#update_loan_information_form").append xhr.responseText
      }).on("ajax:error", (event) => {
      	console.log("error")
          // $("#update_loan_information_form").append "<p>ERROR</p>"
      });

      // iframe.onload = () => sendPostMessage();

      document.addEventListener("turbolinks:before-cache", () => {
        var dataTables = $('.data-table');
        while (dataTables.length !== 0) {
          dataTables.pop().destroy();
        }
      }); 
      // iFrameResize({ log: true }, '#saledge_connect_frame');   
    };
    var embed_responsive_div = document.querySelector('#connect_card_iframe_container');
    embed_responsive_div.appendChild(iframe);

  };
});
