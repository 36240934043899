var xmlhttp8

export const getBlockNo = (lang, lang1) =>
{
//if (str.length==0)
//  {
  //document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
//  return;
//  }
xmlhttp8=GetXmlHttpObject8();
if (xmlhttp8==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
  
var streetnoarr = $("#streetno").val().split("-");

var streetfr = "";
var streetto = "";
if(streetnoarr[0]!=null) {
	streetfr = streetnoarr[0];
}
if(streetnoarr.length>1 && streetnoarr[1]!=null) {
	streetto = streetnoarr[1];
}

var type="";
if($("#street").val()=="") {
	type="E";
}
else {
	type="S";
}

if($("#strtype").val()=="Building") {
	type="B";
}

var url=window.hkpostapi+"/correct_addressing/GetBlockNo.jsp";
url=url+"?building="+encodeURIComponent($("#building").val())+"&lang="+lang+"&lang1="+lang1+"&street="+encodeURIComponent($("#street").val())+"&streetnofr="+streetfr+"&streetnoto="+streetto+"&estate="+encodeURIComponent($("#estate").val())+"&type="+type;
url=url+"&sid="+Math.random();
xmlhttp8.onreadystatechange=stateChangedAtt8;
xmlhttp8.open("GET",url,false);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp8.send(null);
}

function stateChangedAtt8()
{
if (xmlhttp8.readyState==4)
  {
	var allData = xmlhttp8.responseText.replace(/[\r\n]*/g, "").split("|");
	var htmldata = allData[0];
	var no = allData[1];
	if(no!="0" && $("#building").val()!='') {
		$("#blocknoDIV").show();
		document.getElementById("blocknoselect").innerHTML=htmldata;
		$("#blockno").css("width", "");
		$("#blockno").attr("class","form-control custom-select");
	}
	else {
		$("#blocknoDIV").hide();
		$("#blockno").val("");
		//document.getElementById("streetnoselect").innerHTML='<select id="streetno" name="streetno" onchange="getStreetNoDIV();"><option value=""> </option></select>';
	}
  }
}

function GetXmlHttpObject8()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}