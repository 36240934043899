import {getPhaseValue, getStrList, getStreetNoValue1, getBfromE, getEfromStreet, getFloorValue, getUnitValue, setbuildinginput} from "src/address_selector/utils"


var xmlhttp3;

var bbb;

export const getBuilding = (str, str1, str2, lang, str3, str4, str5, str6, str7, status) =>
{
bbb=str3;
if (str.length==0)
  {
  document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
  return;
  } 
xmlhttp3=GetXmlHttpObject3();
if (xmlhttp3==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetBuilding.jsp";
url=url+"?type_value="+str+"&zone="+str1+"&district="+str2+"&lang="+lang+"&building="+encodeURIComponent(str3)+"&estate_name="+encodeURIComponent(str4)+"&street_name="+encodeURIComponent(str5)+"&streetno="+str6+"&phase="+encodeURIComponent(str7)+"&status="+status;
url=url+"&sid="+Math.random();
xmlhttp3.onreadystatechange=stateChangedAtt3;
xmlhttp3.open("GET",url,false);
xmlhttp3.send(null);
// stateChangedAtt3();
}

function stateChangedAtt3()
{
if (xmlhttp3.readyState==4)
  {
  	document.getElementById("buildingselect").innerHTML=xmlhttp3.responseText;
   	document.getElementById("building").className = "styled-select";
    $("#building").css("width", "");
    $("#building").attr("class","form-control selectpicker");
    $("#building").attr("onchange","");
    $("#building").attr("required","");
    $("#building").change(() => {
      getEfromStreet();
      getStrList();
      getStreetNoValue1();
      getFloorValue();
      getUnitValue();
      setbuildinginput();
    });
  	if($('select#building option').length==1) {
		$("#building").attr('disabled', 'disabled');
	}
	else {
		$("#building").removeAttr('disabled');
	}
	if($("#building option[value='"+bbb+"']").length !== 0) {
		$("#building").val(bbb);
	}
	else {
		$("#building").val('');
	}
	
	if($('select#building option').length==2) {
		//$("#building").prop('selectedIndex',1);
		setbuildinginput();
		getEfromStreet();getStrList();getStreetNoValue1();getFloorValue();getUnitValue();setbuildinginput();
	}
  $("#building").select2({
      theme: 'bootstrap4'
    });
	
	//renderSelect();
	//$("#building option[text=" + bbb +"]").attr("selected","selected") ;
  }
}

function GetXmlHttpObject3()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

