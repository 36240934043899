import {getUnitValue, flrdesc1choose} from "src/address_selector/utils"

var xmlhttp17

export const getFloor = (strno, street, estate, phase, building, district, lang1) =>
{

//if (estate.length==0)
//  {
//  document.getElementById("floorselect").innerHTML="<select id='floor' name='floor'><option>Select Floor</option></select>";
//  return;
//  }

xmlhttp17=GetXmlHttpObject17();
if (xmlhttp17==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }

var url=window.hkpostapi+"/correct_addressing/GetFloor.jsp";
url=url+"?strno="+strno+"&street="+street+"&estate="+encodeURIComponent(estate)+"&phase="+encodeURIComponent(phase)+"&building="+encodeURIComponent(building)+"&district="+district+"&lang1="+lang1;

url=url+"&sid="+Math.random();
xmlhttp17.onreadystatechange=stateChangedAtt17;
xmlhttp17.open("GET",url,true);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp17.send(null);

}

function stateChangedAtt17()
{
if (xmlhttp17.readyState==4)
  {
	var floorselect1 = xmlhttp17.responseText;
	//alert("F1:"+floorselect1);
	if(floorselect1.indexOf("Start111")==0) {
		var floorselect1start=floorselect1.indexOf("Start111")+8;
		var floorselect1end=floorselect1.indexOf("End111");
		var floorselectval=floorselect1.substring(floorselect1start, floorselect1end);
		//alert(floorselectval);
		var floorselectvalarr = floorselectval.split("||");
		var floorlocid=floorselectvalarr[0];
		var floorsublocno=floorselectvalarr[1];
		var floorlocnum=floorselectvalarr[2];
		//alert(floorlocid);
		//alert(floorsublocno);
		$("#def_addr_flag").val("Y");
		$("#loc_id1").val(floorlocid);
		$("#sub_loc_no1").val(floorsublocno);
		$("#locnum").val(floorlocnum);
		

		//alert(floorlocid+"!!"+floorsublocno+"!!"+floorlocnum);
		document.getElementById("floorselect").innerHTML="<select id='floor' name='floor'><option value=''>Select Floor</option></select>";
	}
	else if(floorselect1.indexOf("Start111")>0) {
		var floorselect1start=floorselect1.indexOf("Start111")+8;
		var floorselect1end=floorselect1.indexOf("End111");
		var floorselectval=floorselect1.substring(floorselect1start, floorselect1end);
		//alert(floorselectval);
		var floorselectvalarr = floorselectval.split("||");
		var floorlocid=floorselectvalarr[0];
		var floorsublocno=floorselectvalarr[1];
		var floorlocnum=floorselectvalarr[2];
		//alert(floorlocid);
		//alert(floorsublocno);
		$("#loc_id1").val(floorlocid);
		$("#sub_loc_no1").val(floorsublocno);
		$("#locnum").val(floorlocnum);
		$("#def_addr_flag").val("N");
		//$("#loc_id1").val("");
		//$("#sub_loc_no1").val("");
		//$("#locnum").val(1);

		document.getElementById("floorselect").innerHTML=floorselect1.substring(0, floorselect1.indexOf("Start111"));
	}
	$("#floor").css("width", "");
	$("#floor").attr("class","form-control selectpicker");
    $("#floor").attr("onchange","");
	$("#floor").change(() => {
		getUnitValue();
	});
	$("#flrdesc1").attr("onchange","");
	$("#flrdesc1").change(() => {
		flrdesc1choose();
	});
	//alert("F:"+floorselect1);
	
	if($('select#floor option').length<=2) {
		$("#floor").attr('disabled', 'disabled');
		if($("#building").val()!='') {
			//alert("Please input more information");
		}
		//alert("LocnumF:"+$("#locnum").val());
		if(parseInt($("#locnum").val())==1) {
			$("#floor1box").show();
		}
		else {	
			$("#floor1inpute").val("");
			$("#flrdesc1").val("");
			$("#floor1box").hide();
		}
	}
	else {
		$("#floor").removeAttr('disabled');
		$("#floor1inpute").val("");
		$("#flrdesc1").val("");
		$("#floor1box").hide();
	}
	
	if($("#floor").val().substr(0, 9)=="notinlist") {
		$("#floor1box").show();
		$("#unit").val($("#unit option:last-child").val());
		$("#unit").attr("disabled", "disabled");
		$("#unit1box").show();
	}
	else {
		if($('select#floor option').length>2) {
			$("#floor1inpute").val("");
			$("#flrdesc1").val("");
			$("#floor1box").hide();
		}
	}
	
	if($('select#floor option').length==3) {
		$("#floor").prop('selectedIndex',1);
	}
	
	getUnitValue();
	$("#floor").select2({
      theme: 'bootstrap4'
    });
	/*
	if(structerror) {
		redselect('#street');
		redselect('#streetno');
		redselect('#estate');
		redselect('#phase');
		redselect('#building');
		redselect('#floor');
		redselect('#unit');
	}
	*/
}
}

function GetXmlHttpObject17()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

