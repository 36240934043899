var xmlhttp9

export const getPhaseNo = (lang, lang1) =>
{
//if (str.length==0)
//  {
  //document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
//  return;
//  }
xmlhttp9=GetXmlHttpObject9();
if (xmlhttp9==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
  
var streetnoarr = $("#streetno").val().split("-");

var streetfr = "";
var streetto = "";
if(streetnoarr[0]!=null) {
	streetfr = streetnoarr[0];
}
if(streetnoarr.length>1 && streetnoarr[1]!=null) {
	streetto = streetnoarr[1];
}

var type="";
if($("#street").val()=="") {
	type="E";
}
else {
	type="S";
}

var url=window.hkpostapi+"/correct_addressing/GetPhaseNo.jsp";
url=url+"?phase="+encodeURIComponent($("#phase").val())+"&lang="+lang+"&lang1="+lang1+"&street="+encodeURIComponent($("#street").val())+"&streetnofr="+streetfr+"&streetnoto="+streetto+"&estate="+encodeURIComponent($("#estate").val())+"&type="+type;
url=url+"&sid="+Math.random();
xmlhttp9.onreadystatechange=stateChangedAtt9;
xmlhttp9.open("GET",url,false);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp9.send(null);
}

function stateChangedAtt9()
{
if (xmlhttp9.readyState==4)
  {
	var allData = xmlhttp9.responseText.replace(/[\r\n]*/g, "").split("|");
	var htmldata = allData[0];
	var no = allData[1];
	//alert("hihi:"+xmlhttp8.responseText.replace(/[\r\n]*/g, "")+":hihi");
	//alert(htmldata);
	//alert(no);
	//var s="-8";
	//var sa = s.split("-");
	//alert(sa[0]+"!"+sa[1]);
	if(no!="0" && $("#phase").val()!='') {
		$("#phasenoDIV").show();
		document.getElementById("phasenoselect").innerHTML=htmldata;
		$("#phaseno").css("width", "");
	}
	else {
		$("#phasenoDIV").hide();
		$("#phaseno").val("");
		//document.getElementById("streetnoselect").innerHTML='<select id="streetno" name="streetno" onchange="getStreetNoDIV();"><option value=""> </option></select>';
	}
  }
}

function GetXmlHttpObject9()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}