var xmlhttp5

export const getBuilding1 = (str, str1, str2, str3, lang, str4) =>
{
if (str.length==0)
  {
  //document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
  return;
  }
xmlhttp5=GetXmlHttpObject5();
if (xmlhttp5==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetBuilding.jsp";
url=url+"?type_value="+str+"&zone="+str1+"&district="+str2+"&estate="+str3+"&lang="+lang+"&building="+encodeURI(str4);
url=url+"&sid="+Math.random();
xmlhttp5.onreadystatechange=stateChangedAtt5;
xmlhttp5.open("GET",url,true);
xmlhttp5.send(null);
}

function stateChangedAtt5()
{
if (xmlhttp5.readyState==4)
  {
 // document.getElementById("buildingselect").innerHTML=xmlhttp3.responseText;
   document.getElementById("building").className = "styled-select";
   $("#building").css("width", "");
   	var allData = xmlhttp5.responseText.replace(/[\r\n]*/g, "").split("|");
	//alert("hihi:"+xmlhttp4.responseText.replace(/[\r\n]*/g, "")+":hihi");
	var head = allData[0];
	//alert("head:"+head);
    var allSearch = allData[1];
	//alert("allSearch111:" +allSearch);
	var search = allSearch.split(",");
	var availableTags = searchhead(search, head).split(",");
	//alert("hihi:"+xmlhttp5.responseText.replace(/[\r\n]*/g, "")+":hihi");
	if(xmlhttp5.responseText.replace(/[\r\n]*/g, "")!="|") {
		$("#buildingDIV").show();
	}
	else {
		$("#buildingDIV").hide();
		$("#buildingDIV").val("");
	}

	$( "#building" ).autocomplete({

	  source: availableTags,
	  focus: function(event, ui) {
		getB();
	  }

	});
  }
}

function GetXmlHttpObject5()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

