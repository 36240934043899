var xmlhttp1

export const getVillage = (str, str1, str2) =>
{
if (str.length==0)
  {
  //document.getElementById("villageselect").innerHTML="<select id='village' name='village'><option>Select Village</option></select>";
  return;
  }
xmlhttp1=GetXmlHttpObject1();
if (xmlhttp1==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetVillage.jsp";
url=url+"?type_value="+str+"&zone="+str1+"&district="+str2;
url=url+"&sid="+Math.random();
xmlhttp1.onreadystatechange=stateChangedAtt1;
xmlhttp1.open("GET",url,false);
xmlhttp1.send(null);
}

function stateChangedAtt1()
{
if (xmlhttp1.readyState==4)
  {
  //document.getElementById("villageselect").innerHTML=xmlhttp1.responseText;
  var availableTags = xmlhttp1.responseText.split(",");
  //var availableTags = [
//	  xmlhttp1.responseText
//	];

	$( "#village" ).autocomplete({

	  source: availableTags

	});
  }
}

function GetXmlHttpObject1()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}