var xmlhttp46

export const getUnitByAddr2 = (str, str1, flag, lang1) =>
{
	var data = str.split("|");
	var loc_id=data[0];
	var sub_loc_no=data[1];
//if (estate.length==0)
//  {
//  document.getElementById("unitselect").innerHTML="<select id='unit' name='unit'><option>Select Unit</option></select>";
//  return;
//  }

xmlhttp46=GetXmlHttpObject46();
if (xmlhttp46==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }

var url=window.hkpostapi+"/correct_addressing/GetUnitByAddr2.jsp";
url=url+"?loc_id="+loc_id+"&sub_loc_no="+sub_loc_no+"&floor="+encodeURIComponent(str1)+"&iseng="+encodeURIComponent(flag)+"&lang1="+encodeURIComponent(lang1);

url=url+"&sid="+Math.random();
xmlhttp46.onreadystatechange=stateChangedAtt46;
xmlhttp46.open("GET",url,false);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp46.send(null);

}

function stateChangedAtt46()
{
if (xmlhttp46.readyState==4)
  {
	document.getElementById("unitaddrselect2").innerHTML=xmlhttp46.responseText;
	$("#unitaddr").css("width", "");
	 if($('select#unit2 option').length<=2) {
		$("#unit2").attr('disabled', 'disabled');
		$("#unit2box").show();
	}
	else {
		$("#unit2").removeAttr('disabled');
		$("#unit2inpute").val("");
		$("#unitdesc2").val("");
		$("#unit2box").hide();
	}
	
	//if($('select#unit2 option').length==3) {
	//	$("#unit2").prop('selectedIndex',1);
	//}
	
	if(($('select#floor2 option').length==2 || $('select#floor2 option').length==3) && $('select#unit2 option').length==3) {
		$("#unit2").prop('selectedIndex',1);
	}
	else if($("#floor2").val()=="" && $('select#floor2 option').length>2) {
		$("#unit2").attr('disabled', 'disabled');
	}
	
	if(structerror2) {
		redselect('#floor2');
		redselect('#unit2');
	}
	
	//if($("#floor2").val().substr(0, 9)=="notinlist") {
	//	$("#unit2 option").eq($("#unit2 option").length).prop('selected', true);
	//}
}
}

function GetXmlHttpObject46()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

