var xmlhttp22
var iseng2="false";
var a1=1;
var a2=1;
var a3=1;
//var img1="<img src='nostatusarrow.jpg' border='0'>";
//var img2="<img src='nostatusarrow.jpg' border='0'>";
//var img3="<img src='nostatusarrow.jpg' border='0'>";
var img1="<img src='Sort.png' border='0'>";
var img2="<img src='Sort.png' border='0'>";
var img3="<img src='Sort.png' border='0'>";
var lll="";
var flagflag1="";
var input1s="";
var input2s="";
var input3s="";
var input4s="";
var input5s=0;
var recordnos=10;
var input6s="";

function replaceAllCode(str, a, b) {
	var result="";
	for(var i=0; i<str.length; i++) {
		var s = str.charAt(i);
		if(s==a) {
			s=b;
		}
		result+=s;
	}
	return result;
}

export const getStreetAddr = (str, flag, lang1, n, currpage, key) =>
{
input1s=replaceAllCode(str, "'", "&#39;");
input2s=flag;
input3s=lang1;
input4s=n;
input5s=currpage;
input6s=replaceAllCode(key, "'", "&#39;");
lll=lang1;
flagflag1=flag;
var a='';




if(n=='1') {
	a1++;
	a2=0;
	a3=0;	
	a=a1;
}
if(n=='2') {
	a2++;
	a1=0;
	a3=0;
	a=a2;
}
if(n=='3') {
	a3++;
	a1=0;
	a2=0;
	a=a3;
}
if(n=='51') {
	a2=0;
	a3=0;	
	a=a1;
}
if(n=='52') {
	a1=0;
	a3=0;
	a=a2;
}
if(n=='53') {
	a1=0;
	a2=0;
	a=a3;
}
if(n=='4' || n=='54') {
	a1=0;
	a2=0;
	a3=1;
	a=a3;
}


if(n=='1' || n=='51') {
	if(a1%2==0) {
		img1="<img src='descend.png' border='0'>";
	}
	else {
		img1="<img src='ascend.png' border='0'>";
	}
}
else {
	img1="<img src='Sort.png' border='0'>";
}
if(n=='2' || n=='52') {
	if(a2%2==0) {
		img2="<img src='descend.png' border='0'>";
	}
	else {
		img2="<img src='ascend.png' border='0'>";
	}
}
else {
	img2="<img src='Sort.png' border='0'>";
}
if(n=='3' || n=='53' || n=='4' || n=='54') {
	if(a3%2==0) {
		img3="<img src='descend.png' border='0'>";
	}
	else {
		img3="<img src='ascend.png' border='0'>";
	}
}
else {
	img3="<img src='Sort.png' border='0'>";
}
iseng2=flag;
if (str.length==0)
  {
  //document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
  return;
  }
xmlhttp22=GetXmlHttpObject22();
if (xmlhttp22==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetStreetAddr.jsp";
url=url+"?street="+encodeURIComponent(str)+"&iseng="+flag+"&lang1="+lang1+"&n="+n+"&a="+a+"&currpage="+currpage+"&key="+encodeURIComponent(key)+"&img1="+encodeURIComponent(img1)+"&img2="+encodeURIComponent(img2)+"&img3="+encodeURIComponent(img3);
url=url+"&sid="+Math.random();
//xmlhttp22.onreadystatechange=stateChangedAtt22;
xmlhttp22.open("GET",url,false);
xmlhttp22.setRequestHeader("Accept-Charset","utf-8");
xmlhttp22.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp22.send(null);
stateChangedAtt22();
}

function stateChangedAtt22()
{
if (xmlhttp22.readyState==4)
  {
	var result = xmlhttp22.responseText;
	var nextb="";
	var preb="";
	var gotot="";
	var totalstr="";
	var recordstr="";

	
	if(lll=="en_US") {
		preb="&lt;Prev&gt;";
		nextb="&lt;Next&gt;";
		gotot="Go to page: ";
		totalstr="Total";
		recordstr="Records";
	}
	else if(lll=="zh_TW") {
		preb="&lt;上一頁&gt;";
		nextb="&lt;下一頁&gt;";
		gotot="跳到: ";
		totalstr="總共";
		recordstr="筆紀錄";
	}
	else {
		preb="&lt;上一页&gt;";
		nextb="&lt;下一页&gt;";
		gotot="跳到: ";
		totalstr="总共";
		recordstr="笔纪录";
	}
	
	if(result.indexOf("hihibyebye")==-1) {
		document.getElementById("streetaddrselect").innerHTML=result;
		$("#streetaddr").css("width", "");
		$("#rdsearchDIV2").show();
		
		var prep=parseInt(input5s)-1;
		if(prep<1) {
			prep=1;
		}
		var nextp=parseInt(input5s)+1;
		var totalp=1;
		if($("#sno").val()!=null) {
			totalp=Math.ceil($("#sno").val()/recordnos);
		}
		
		if(nextp>=totalp) {
			nextp=totalp;
		}
		
		if(input4s.length>1) {
			input4s=input4s.substr(1, 1);
		}
		
		if(totalp==0) {
			totalp=1;
		}
		
		var pagepart1="<a onMouseOver='this.style.cursor=\"pointer\"' onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", \"5"+input4s+"\", "+prep+", \""+input6s+"\");'><font color='#5555ff'><b>"+preb+"</b></font></a> "+input5s+" / "+totalp+" <a onMouseOver='this.style.cursor=\"pointer\"' onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", \"5"+input4s+"\", "+nextp+", \""+input6s+"\");'><font color='#5555ff'><b>"+nextb+"</b></font></a> &nbsp; ";

		var pagepart2="<select id='selectspage' onchange='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", \"5"+input4s+"\", this.value, \""+input6s+"\");'>";
		
		for(var j=1; j<=totalp; j++) {
			if(j==input5s) {
				pagepart2+="<option value="+j+" selected>"+j+"</option>";
			}
			else {
				pagepart2+="<option value="+j+">"+j+"</option>";
			}
		}
		pagepart2+="</select>";
		
		document.getElementById("saddrpage").innerHTML=pagepart1+" &nbsp; "+gotot+pagepart2;
		document.getElementById("totalnoofrecords").innerHTML="<b>"+totalstr+" "+$("#sno").val()+" "+recordstr+"</b>";
		
		if(iseng2=="true") {
			//document.getElementById("streetaddrselectheader").innerHTML="<table style='border: 1px solid #AAAAAA; border-collapse: collapse; color: #000000;' width='760pt'><tr><th width='19pt' style='border: 1px solid black;'>&nbsp;</th><th onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", 3, 1, \""+input6s+"\");' width='205pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>District </td><td valign='middle' style='border: 0px;'>"+img3+"</td></tr></table></th><th onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", 2, 1, \""+input6s+"\");' width='230pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>Street </td><td valign='middle' style='border: 0px;'>"+img2+"</td></tr></table></th><th onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", 1, 1, \""+input6s+"\");' width='255pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>Building </td><td valign='middle' style='border: 0px;'>"+img1+"</td></tr></table></th></tr></table>";
		}
		else {
			//document.getElementById("streetaddrselectheader").innerHTML="<table style='border: 1px solid #AAAAAA; border-collapse: collapse; color: #000000;' width='760pt'><tr><th width='19pt' style='border: 1px solid black;'>&nbsp;</th><th onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", 3, 1, \""+input6s+"\");' width='205pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;' ><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>地區 </td><td valign='middle' style='border: 0px;'>"+img3+"</td></tr></table></th><th onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", 2, 1, \""+input6s+"\");' width='230pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>街道名稱 </td><td valign='middle' style='border: 0px;'>"+img2+"</td></tr></table></th><th onclick='getStreetAddr(\""+input1s+"\", \""+input2s+"\", \""+input3s+"\", 1, 1, \""+input6s+"\");' width='255pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>樓宇名稱 </td><td valign='middle' style='border: 0px;'>"+img1+"</td></tr></table></th></tr></table>";
		}
		if($("#sno").val()=="1") {
			$("#streetaddr1").attr("checked", true);
			getFloorByAddrValue2();
			getUnitByAddrValue2();
		}
		
		if(totalp==1 && $("#streetinputkeyreal").val()=="") {
			hidekeys();
		}
		
		//if(result.indexOf("hihibyebye")>-1) {
		if($("#sno").val()=="0") {
			//$("#rdsearchDIV2").hide();
			if(lll=="en_US") {
				alert("No record find!");
			}
			else if(lll=="zh_TW") {
				alert("找不到紀錄!");
			}
			else {
				alert("找不到纪录!");
			}
		}
		
		renderResultTable();
		
		$("#streetaddrselectheader").scrolltop();
		$("#rdsearch2").focus();
	}
  }
}

function GetXmlHttpObject22()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}