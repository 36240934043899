var xmlhttp24

export const getUnitByAddr = (str, str1, flag, lang1) =>
{
	var data = str.split("|");
	var loc_id=data[0];
	var sub_loc_no=data[1];
//if (estate.length==0)
//  {
//  document.getElementById("unitselect").innerHTML="<select id='unit' name='unit'><option>Select Unit</option></select>";
//  return;
//  }

xmlhttp24=GetXmlHttpObject24();
if (xmlhttp24==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }

var url=window.hkpostapi+"/correct_addressing/GetUnitByAddr.jsp";
url=url+"?loc_id="+loc_id+"&sub_loc_no="+sub_loc_no+"&floor="+encodeURIComponent(str1)+"&iseng="+encodeURIComponent(flag)+"&lang1="+encodeURIComponent(lang1);

url=url+"&sid="+Math.random();
xmlhttp24.onreadystatechange=stateChangedAtt24;
xmlhttp24.open("GET",url,true);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp24.send(null);

}

function stateChangedAtt24()
{
if (xmlhttp24.readyState==4)
  {
	document.getElementById("unitaddrselect").innerHTML=xmlhttp24.responseText;
  $("#unitaddr").css("width", "");
	 if($('select#unit option').length==1) {
		$("#unit").attr('disabled', 'disabled');
	}
	else {
		$("#unit").removeAttr('disabled');
	}
}
}

function GetXmlHttpObject24()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

