
import {setstreetinput, resetStreetno, getPhaseValue, getStrList, getStreetNoValue, getStreetNoValue1, getBfromE, getBfromStreet, getEfromStreet, getFloorValue, getUnitValue, setbuildinginput} from "src/address_selector/utils"

var xmlhttp2;
var sss;
export const getStreet = (str, str1, str2, lang, str3, estate, phase, building) =>
{
sss=str3;
//$("#streetno").val("");
if (str.length==0)
  {
  document.getElementById("streetselect").innerHTML="<select id='street' name='street' style='width: 180pt;'><option>Select Street</option></select>";
  return;
  }
xmlhttp2=GetXmlHttpObject2();
if (xmlhttp2==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetStreet.jsp";
url=url+"?type_value="+str+"&zone="+str1+"&district="+str2+"&lang="+lang+"&street="+encodeURIComponent(str3)+"&estate="+encodeURIComponent(estate)+"&phase="+encodeURIComponent(phase)+"&building="+encodeURIComponent(building);
url=url+"&sid="+Math.random();
//xmlhttp2.onreadystatechange=stateChangedAtt2;
xmlhttp2.open("GET",url,false);
//xmlhttp2.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp2.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp2.send(null);
stateChangedAtt2();
}

function stateChangedAtt2()
{
if (xmlhttp2.readyState==4)
  {
  document.getElementById("streetselect").innerHTML=xmlhttp2.responseText;
  $("#street").css("width", "");
  $("#street").attr("class","form-control selectpicker");
  $("#street").attr("onchange","");
  $("#street").change(() => {
		resetStreetno();
		getStreetNoValue();
		getEfromStreet();
		getPhaseValue();
		getBfromStreet();
		getFloorValue();
		getUnitValue();
		setstreetinput();
	});
  	if($('select#street option').length==1) {
		$("#street").attr('disabled', 'disabled');
	}
	else {
		$("#street").removeAttr('disabled');
	}
	if($("#street option[value='"+sss+"']").length !== 0) {
		$("#street").val(sss);
	}
	else {
		$("#street").val('');
	}
	
	if($('select#street option').length==2) {
		//$("#street").prop('selectedIndex',1);
		//getFloorValue();getUnitValue();
		setstreetinput();
	}
	$("#street").select2({
      theme: 'bootstrap4'
    });
  }
}

function GetXmlHttpObject2()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

function encode(string) {
	string = string.replace(/\r\n/g,"\n");
	var utftext = "";

	for (var n = 0; n < string.length; n++) {

		var c = string.charCodeAt(n);

		if (c < 128) {
			utftext += String.fromCharCode(c);
		}
		else if((c > 127) && (c < 2048)) {
			utftext += String.fromCharCode((c >> 6) | 192);
			utftext += String.fromCharCode((c & 63) | 128);
		}
		else {
			utftext += String.fromCharCode((c >> 12) | 224);
			utftext += String.fromCharCode(((c >> 6) & 63) | 128);
			utftext += String.fromCharCode((c & 63) | 128);
		}

	}

	return utftext;
}
	
function decode(utftext) {
	var string = "";
	var i = 0;
	var c = c1 = c2 = c3 = 0;

	while ( i < utftext.length ) {

		c = utftext.charCodeAt(i);

		if (c < 128) {
			string += String.fromCharCode(c);
			i++;
		}
		else if((c > 191) && (c < 224)) {
			c2 = utftext.charCodeAt(i+1);
			string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
			i += 2;
		}
		else {
			c2 = utftext.charCodeAt(i+1);
			c3 = utftext.charCodeAt(i+2);
			string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
			i += 3;
		}

	}

	return string;
}