import {getDistrict} from "src/address_selector/getDistrict"
import {getStreet} from "src/address_selector/getStreet"
import {getStreetNo} from "src/address_selector/getStreetNo"
import {getBuilding} from "src/address_selector/getBuilding"
import {getEstate} from "src/address_selector/getEstate"
import {getFloor} from "src/address_selector/getFloor"
import {getUnit} from "src/address_selector/getUnit"
import {getPhaseV} from "src/address_selector/getPhase"
require("select2")

export var structerror = false;
export var locale = "en_US";
if (document.documentElement.lang == "zh-HK") {
	locale = "zh_TW";
}

export const setbuildinginput = () => {
	var bbbb =$("#building").select2('data')[0].id;
	if(bbbb=="") {
		bbbb="nullhihinullhihinull";
	}
	var bbbbarr = bbbb.split("hihi");
	var bname = bbbbarr[2];
	var eeee=$("#estate").select2('data')[0].id;
	if(bbbb!="" && bname!="null") {
		$("#buildinginput").val(bname);
		$("#buildinginput1").val(bname);
		$("#buildinginput2").val(bname);
	}
	else {
		$("#buildinginput").val(eeee);
		$("#buildinginput1").val(eeee);
		$("#buildinginput2").val(eeee);
	}
}

export const getunitfunction = () => {
	if($("#unit").select2('data')[0].id.substr(0, 9)=="notinlist") {
		$("#unit1box").show();
	}
	else {
		$("#unit1inpute").val("");
		$("#unitdesc1").val("");
		$("#unit1box").hide();
	}
	
	if(structerror) {
		redselect('#street');
		redselect('#streetno');
		redselect('#estate');
		redselect('#phase');
		redselect('#building');
		redselect('#floor');
		redselect('#unit');
	}
}

export const flrdesc1choose = () => {
	if($("#flrdesc1").select2('data').length==0) {
		resetredselect('#flrdesc1');
	}
}

export const unitdesc1choose = () => {
	if($("#unitdesc1").select2('data').length==0) {
		resetredselect('#unitdesc1');
	}
}

export const flrdesc2choose = () => {
	if($("#flrdesc2").select2('data').length==0) {
		resetredselect('#flrdesc2');
	}
}

export const unitdesc2choose = () => {
	if($("#unitdesc2").select2('data').length==0) {
		resetredselect('#unitdesc2');
	}
}

export const flrdesc3choose = () => {
	if($("#flrdesc3").select2('data').length==0) {
		resetredselect('#flrdesc3');
	}
}

export const unitdesc3choose = () => {
	if($("#unitdesc3").select2('data').length==0) {
		resetredselect('#unitdesc3');
	}
}

export const getBfromE = () => {
	//if($("#street").select2('data')[0].id=="") {
	//	$("#streetno").val('');
	//}
	//$("#building").val('');
	getBuilding('Building', $("#zone").select2('data')[0].id, $("#district").select2('data')[0].id, locale, $("#building").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id, $("#phase").select2('data')[0].id, '1');
}

export const getEfromStreet = () => {
	//$("#estate").val('');
	getEstate('Estate', $("#zone").select2('data')[0].id, $("#district").select2('data')[0].id, locale, $("#estate").select2('data')[0].id,  $("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id, '1');
}

export const getBfromStreet = () => {
	//$("#building").val('');
	getBuilding('Building', $("#zone").select2('data')[0].id, $("#district").select2('data')[0].id, locale, $("#building").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id,  $("#phase").select2('data')[0].id, '2');
}

export const getStreetNoValue = () => {
	getStreetNo($("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id, locale, locale, $("#district").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id);
}

export const getStreetNoValue1 = () => {
	//if($("#street").select2('data')[0].id=="") {
		getStreetNo($("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id, locale, locale, $("#district").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id);
	//}
}

export const getStrList = () => {
	getStreet('Street', $("#zone").select2('data')[0].id, $("#district").select2('data')[0].id, locale, $("#street").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id);
}

export const valuehide = (value) => {
	//$(value+"DIV").hide();
	$(value).val("");
}

export const start = () => {
	$("#rdsearchDIV").hide();
	$("#rdsearchDIV2").hide();
	$("#rdsearchDIV3").hide();
}

export const getDistrictDIV = () => {
	if($("#zone").select2('data').length == 0) {	
		valuehide("#district");
		valuehide("#strtype");
		valuehide("#village");
		valuehide("#street");
		valuehide("#streetno");
		valuehide("#building");
		valuehide("#blockno");
		valuehide("#phase");
		valuehide("#phaseno");
		valuehide("#estate");
	}else {					
		$("#districtDIV").show();
		getDistrict($("#zone").select2('data')[0].id, '', locale, '');
		valuehide("#strtype");
		valuehide("#village");
		valuehide("#street");
		valuehide("#streetno");
		valuehide("#building");
		valuehide("#blockno");
		valuehide("#phase");
		valuehide("#phaseno");
		valuehide("#estate");
	}
}


export const getPhaseValue = () => {
	getPhaseV(locale, locale, $("#estate").select2('data')[0].id, $("#district").select2('data')[0].id, $("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id, $("#phase").select2('data')[0].id);
}

export const getFloorValue = () => {
	getFloor($("#streetno").select2('data')[0].id, $("#street").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id, $("#district").select2('data')[0].id, locale);
}

export const getUnitValue = () => {
	if($("#floor").select2('data')[0].id.substr(0, 9)=="notinlist" || $('select#floor option').length<=2) {
		$("#floor1box").show();
		$("#unit").val($("#unit option:last-child"));
		$("#unit").attr("disabled", "disabled");
		$("#unit1box").show();
		if($('select#floor option').length<=2) {
			getUnit($("#streetno").select2('data')[0].id, $("#street").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id, $("#district").select2('data')[0].id, $("#floor").select2('data')[0].id, locale);
		}
	}
	else {
		//alert('1');
		if($('select#floor option').length>2) {
			$("#floor1inpute").val("");
			$("#flrdesc1").val("");
			$("#floor1box").hide();
		}
		getUnit($("#streetno").select2('data')[0].id, $("#street").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id, $("#district").select2('data')[0].id, $("#floor").select2('data')[0].id, locale);
	}
}

export const setstreetinput = () => {
	var ssss=$("#street").select2('data')[0].id;
	$("#streetinput").val(ssss);
	$("#streetinput1").val(ssss);
	$("#streetinput3").val(ssss);
}

function checkregiondistrict() {
	if($("#zone").select2('data').length==0) {
		alert("Please select Region!");
		return false;
	}
	if($("#district").select2('data').length==0) {
		alert("Please select District!");
		return false;
	}
	return true;
}

export const resetStreetno = () => {
	$("#streetno").val("");
}
export const resetredselect = (name) => {
	if(name=="#flrdesc1" || name=="#unitdesc1" || name=="#flrdesc2" || name=="#unitdesc2" || name=="#flrdesc3" || name=="#unitdesc3") {
		$(name+"select").css("border", "0px solid #888888");
	}
	else {
		if(name=="#floor2" || name=="#floor3" || name=="#unit2" || name=="#unit3") {
			var name1 = name.substr(0, name.length-1)+"addrselect"+name.substr(name.length-1, name.length);
			$(name1).css("border", "0px solid #888888");
			//$(name).css("overflow", "hidden");
			$(name+"str").css("color", "#220066");
		}
		else {
			$(name+"select").css("border", "0px solid #888888");
			//$(name).css("overflow", "hidden");
			$(name+"str").css("color", "#220066");
		}

		//$(name).css("background", "transparent");
	}
}

export const redselect = (name) => {

	if(name=="#flrdesc1" || name=="#unitdesc1" || name=="#flrdesc2" || name=="#unitdesc2" || name=="#flrdesc3" || name=="#unitdesc3") {
		if($(name).select2('data').length==0) {
			$(name+"select").css("border", "2px solid #ff0000");
		}
		else {
			$(name+"select").css("border", "0px solid #888888");
		}
	}
	else if(name=="#floor" || name=="#unit") {
		if($(name).select2('data').length==0 && $('select'+name+' option').length>=3) {
				$(name+"select").css("border", "2px solid #ff0000");
				//$(name).css("overflow", "hidden");
				$(name+"str").css("color", "#ff0000");
			}
			else {
				$(name+"select").css("border", "0px solid #888888");
				//$(name).css("overflow", "hidden");
				$(name+"str").css("color", "#220066");
			}
	}
	else if(name=="#floor2" || name=="#floor3" || name=="#unit2" || name=="#unit3") {
			var name1 = name.substr(0, name.length-1)+"addrselect"+name.substr(name.length-1, name.length);
			if($(name).select2('data')[0].id=="" && $('select'+name+' option').length>=3) {
				$(name1).css("border", "2px solid #ff0000");
				//$(name).css("overflow", "hidden");
				$(name+"str").css("color", "#ff0000");
			}
			else {
				$(name1).css("border", "0px solid #888888");
				//$(name).css("overflow", "hidden");
				$(name+"str").css("color", "#220066");
			}
	}
	else if($(name).select2('data').length==0 && $('select'+name+' option').length>=2) {
		if(parseInt($("#locnum").select2('data')[0].id)>1) {
			$(name+"select").css("border", "2px solid #ff0000");
			//$(name).css("overflow", "hidden");
			$(name+"str").css("color", "#ff0000");
		}
		else {
			$(name+"select").css("border", "0px solid #888888");
			//$(name).css("overflow", "hidden");
			$(name+"str").css("color", "#220066");
		}
	}
	else {
		$(name+"select").css("border", "0px solid #888888");
		//$(name).css("overflow", "hidden");
		$(name+"str").css("color", "#220066");
	}
		//$(name).css("background", "transparent");
}


export const rdsearchval = () => {
	if(checkregiondistrict()) {
		if(structerror) {
			resetredselect('#street');
			resetredselect('#streetno');
			resetredselect('#estate');
			resetredselect('#phase');
			resetredselect('#building');
			resetredselect('#floor');
			resetredselect('#unit');
			resetredselect('#flrdesc1');
			resetredselect('#unitdesc1');
		}
		structerror=false;
		//alert($("#district").select2('data')[0].id+":"+$("#zone").select2('data')[0].id);
		$("#building").val('');
		$("#estate").val('');
		$("#street").val('');
		$("#streetno").val('');
		$("#phase").val('');
		$("#floor").val('');
		$("#unit").val('');
		$("#floor1inpute").val("");
		$("#floor1inputc").val("");
		$("#unit1inpute").val("");
		$("#unit1inputc").val("");
		$("#floor1box").hide();
		$("#unit1box").hide();
		$("#streetno").attr('disabled', 'disabled');
		getStreet('Street', $("#zone").select2('data')[0].id, $("#district").select2('data')[0].id, locale, $("#street").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id);
		//getStreetNoValue();
		getBuilding('Building', $("#zone").select2('data')[0].id, $("#district").select2('data')[0].id, locale, $("#building").select2('data')[0].id, $("#estate").select2('data')[0].id, $("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id, $("#phase").select2('data')[0].id, '0');
		getEstate('Estate', $("#zone").select2('data')[0].id, $("#district").select2('data')[0].id, locale, $("#estate").select2('data')[0].id, $("#street").select2('data')[0].id, $("#streetno").select2('data')[0].id, $("#phase").select2('data')[0].id, $("#building").select2('data')[0].id, '0');
		getFloorValue();
		getUnitValue();
		$("#rdsearchDIV").show();
		
		
	}
}

function renderButton(){	
	$("#addrvalidation :button").each(function(){
		$(this).addClass("newButtonStyle");
	});
	$("#addrvalidation2 :button").each(function(){
		$(this).addClass("newButtonStyle");
	});
	$("#addrvalidation3 :button").each(function(){
		$(this).addClass("newButtonStyle");
	});
	
	$(".rlbutton").each(function(){
		$(this).addClass("newButtonStyle1");
	});
}

export const setupSelectEvents = () => {
	$("#zone").on("select2:select", function (e) {
    getDistrictDIV();
    start();
  });
  $("#district").on("select2:select", (e) => {
    getDistrictDIV();
    start();
  });
  $("#estate").on("select2:select", (e) => {
    getStrList();
    getStreetNoValue1();
    getPhaseValue();
    getBfromE();
    getFloorValue();
    getUnitValue();
    setbuildinginput();
  });
  $("#phase").on("select2:select", (e) => {
    getStrList();
    getStreetNoValue1();
    getBfromE();
    getEfromStreet();
    getFloorValue();
    getUnitValue();
  });
  $("#street").on("select2:select", (e) => {
    resetStreetno();
    getStreetNoValue();
    getEfromStreet();
    getPhaseValue();
    getBfromStreet();
    getFloorValue();
    getUnitValue();
    setstreetinput();
  });

  $("#building").on("select2:select", (e) => {
    getEfromStreet();
    getStrList();
    getStreetNoValue1();
    getFloorValue();
    getUnitValue();
    setbuildinginput();
  });
  $("#floor").on("select2:select", (e) => {
    getUnitValue();
  });
  $("#flrdesc1").on("select2:select", (e) => {
    flrdesc1choose();
  });
  $("#unit").on("select2:select", (e) => {
    getunitfunction();
  });
  $("#unitdesc1").on("select2:select", (e) => {
    unitdesc1choose();
  });

	renderButton();
};

