import {rdsearchval, getDistrictDIV, start} from "src/address_selector/utils"

var xmlhttp
var dval='';
var status111 = "0";
export const getDistrict = (str, lang, lang1, val, status) =>
{
dval=val;
status111=status;
if (str.length==0)
  {
  document.getElementById("districtselect").innerHTML="<select id='district' name='district'><option>Select District</option></select>";
  return;
  }
xmlhttp=GetXmlHttpObject();
if (xmlhttp==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetDistrict.jsp";
url=url+"?zone_value="+str+"&lang="+lang+"&lang1="+lang1;
url=url+"&sid="+Math.random();
xmlhttp.onreadystatechange=stateChangedAtt;
xmlhttp.open("GET",url,true);
xmlhttp.send(null);
}

function stateChangedAtt()
{
if (xmlhttp.readyState==4)
  {
    document.getElementById("districtselect").innerHTML=xmlhttp.responseText;
    $("#district").css("width", "");
    $("#district").attr("class","form-control selectpicker");
    $("#district").attr("onchange","");
    $("#district").attr("required","");
    // $("#district").on("select2:select", (e) => {
      // getDistrictDIV();
      // start();
    // });
    if($("#district option[value='"+dval+"']").length !== 0) {
  		$("#district").val(dval);
  		if(status111=="1") {
  			rdsearchval();
  		}
  	} else {
  		$("#district").val('');
  	}
  }
  $("#district").change(function(){
    rdsearchval();
  });
  $("#district").select2({
      theme: 'bootstrap4'
    });
}

function GetXmlHttpObject()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}