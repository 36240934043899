var xmlhttp4;

var eee;

import {getPhaseValue, getStrList, getStreetNoValue1, getBfromE, getFloorValue, getUnitValue, setbuildinginput} from "src/address_selector/utils"
import {getDistrict} from "src/address_selector/getDistrict"
import {getVillage} from "src/address_selector/getVillage"
import {getStreet} from "src/address_selector/getStreet"
import {getStreetNo} from "src/address_selector/getStreetNo"
import {getBuilding} from "src/address_selector/getBuilding"
import {getBuilding1} from "src/address_selector/getBuilding1"
import {getSB} from "src/address_selector/getSB"
import {getBlockNo} from "src/address_selector/getBlockNo"
import {getPhase} from "src/address_selector/getPhase"
import {getPhaseNo} from "src/address_selector/getPhaseNo"
import {getFloor} from "src/address_selector/getFloor"
import {getUnit} from "src/address_selector/getUnit"
import {getStreetInput} from "src/address_selector/getStreetInput"
import {getStreetAddr} from "src/address_selector/getStreetAddr"
import {getBuildingInput} from "src/address_selector/getBuildingInput"
import {getBuildingAddr} from "src/address_selector/getBuildingAddr"
import {getFloorByAddr} from "src/address_selector/getFloorByAddr"
import {getUnitByAddr} from "src/address_selector/getUnitByAddr"
import {getFloorByAddr2} from "src/address_selector/getFloorByAddr2"
import {getUnitByAddr2} from "src/address_selector/getUnitByAddr2"


export const getEstate = (str, str1, str2, lang, str3, str4, str5, phase, building, status) =>
{
eee=str3;
if (str.length==0)
  {
  document.getElementById("estateselect").innerHTML="<select id='estate' name='estate'><option>Select Estate</option></select>";
  return;
  }
  
xmlhttp4=GetXmlHttpObject4();
if (xmlhttp4==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetEstate.jsp";
url=url+"?type_value="+str+"&zone="+str1+"&district="+str2+"&lang="+lang+"&estate="+encodeURIComponent(str3)+"&street="+encodeURIComponent(str4)+"&streetno="+encodeURIComponent(str5)+"&status="+status+"&phase="+encodeURIComponent(phase)+"&building="+encodeURIComponent(building);
url=url+"&sid="+Math.random();
xmlhttp4.onreadystatechange=stateChangedAtt4;
xmlhttp4.open("GET",url,false);
xmlhttp4.send(null);
// stateChangedAtt4();
}

function stateChangedAtt4()
{
if (xmlhttp4.readyState==4)
  {
	document.getElementById("estateselect").innerHTML=xmlhttp4.responseText;
	$("#estate").css("width", "");
	$("#estate").attr("class","form-control selectpicker");
	$("#estate").attr("onchange","");
    $("#estate").on("select2:select", (e) => {
		getStrList();
		getStreetNoValue1();
		getPhaseValue();
		getBfromE();
		getFloorValue();
		getUnitValue();
		setbuildinginput();
	});
   if($('select#estate option').length==1) {
		$("#estate").attr('disabled', 'disabled');
	}
	else {
		$("#estate").removeAttr('disabled');
	}
	if($("#estate option[value='"+eee+"']").length !== 0) {
		$("#estate").val(eee);
	}
	else {
		$("#estate").val('');
	}
	
	if($('select#estate option').length==2) {
		//$("#estate").prop('selectedIndex',1);
		// getStrList();
		// getStreetNoValue1();
		// getPhaseValue();
		// getBfromE();
		// getFloorValue();
		// getUnitValue();
		// setbuildinginput();
	}
	else {
		getPhaseValue();
	}
	$("#estate").select2({
      theme: 'bootstrap4'
    });
	//$("#estate option[text=" + eee +"]").attr("selected","selected") ;
	
  }
}

function GetXmlHttpObject4()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}