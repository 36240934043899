
var xmlhttp30

export const getBuildingInput = (str, flag) =>
{
//if (str.length==0)
//  {
  //document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
//  return;
 // }
xmlhttp30=GetXmlHttpObject30();
if (xmlhttp30==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetBuildingInput.jsp";
url=url+"?building="+encodeURIComponent(str)+"&flag="+flag;
url=url+"&sid="+Math.random();
xmlhttp30.onreadystatechange=stateChangedAtt30;
xmlhttp30.open("GET",url,true);
//xmlhttp30.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp30.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp30.send(null);
//stateChangedAtt30();
}

function stateChangedAtt30()
{
if (xmlhttp30.readyState==4)
  {
 // document.getElementById("buildingselect").innerHTML=xmlhttp3.responseText;
	//alert(xmlhttp21.responseText);
	var allData = xmlhttp30.responseText.replace(/[\r\n]*/g, "").split("|");
	////alert("hihi:"+xmlhttp2.responseText.replace(/[\r\n]*/g, "")+":hihi");
	var head = allData[0];
	//alert("head:"+head);
    var allSearch = allData[1];
	//alert('sss:'+allSearch);
	var search = allSearch.split(",");
	var availableTags = searchhead(search, head).split(",");
	
	//var availableTags = xmlhttp2.responseText.split(",");
	//alert("hihi:"+xmlhttp2.responseText+":hihi");
	//var t="";
	//if(str=="Street") {
	//	t="street";
	//}
	//else if(str=="Estate") {
	//	t="estate";
	//}
	//else if(str=="Building") {
	//	t="building";
	//}

	$( "#buildinginput" ).autocomplete({

	  source: availableTags,
	  focus: function(event, ui) {
		//$( "#buildinginput" ).val(head);
		//streetinputonkeyup();
	  }

	});
	$("#rdsearchDIV").hide();
  }
}

function GetXmlHttpObject30()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}