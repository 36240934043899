import {getPhaseValue, getStrList, getStreetNoValue1, getBfromE, getBfromStreet, getEfromStreet, getFloorValue, getUnitValue, setbuildinginput} from "src/address_selector/utils"


var xmlhttp16;
var sssno;
export const getStreetNo = (str, strno, lang, lang1, distr, estate, phase, building) =>
{
sssno=strno;
// if (str.length==0)
//   {
//   document.getElementById("streetnoselect"). innerHTML="<select style='width:113pt;' id='streetno' name='streetno'><option value=''>Select Street No</option></select>";
//   $("#streetno").attr('disabled', 'disabled');
//   return;
//   }
xmlhttp16=GetXmlHttpObject16();
if (xmlhttp16==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetStreetNo.jsp";
url=url+"?street="+encodeURIComponent(str)+"&lang="+lang+"&lang1="+lang1+"&district="+distr+"&estate="+encodeURIComponent(estate)+"&phase="+encodeURIComponent(phase)+"&building="+encodeURIComponent(building);
url=url+"&sid="+Math.random();
xmlhttp16.onreadystatechange=stateChangedAtt16;
xmlhttp16.open("GET",url,false);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp16.send(null);
// stateChangedAtt16();
}

function stateChangedAtt16()
{
if (xmlhttp16.readyState==4)
  {
	document.getElementById("streetnoselect").innerHTML=xmlhttp16.responseText;
  $("#streetno").css("width", "");
  $("#streetno").attr("class","form-control selectpicker");
  $("#streetno").attr("onchange","");
  $("#streetno").change(() => {
	    getEfromStreet();
	    getBfromStreet();
	    getFloorValue();
	    getUnitValue();
	});
	if($('select#streetno option').length==1) {
		$("#streetno").attr('disabled', 'disabled');
	}
	else {
		$("#streetno").removeAttr('disabled');
	}
	if($("#streetno option[value='"+sssno+"']").length !== 0) {
		$("#streetno").val(sssno);
	}
	else {
		$("#streetno").val('');
	}
	
	if($('select#streetno option').length==2) {
		//$("#streetno").prop('selectedIndex',1);
		//getFloorValue();getUnitValue();
	}
	$("#streetno").select2({
      theme: 'bootstrap4'
    });
  }
}

function GetXmlHttpObject16()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}