var xmlhttp31
var iseng1="false";
var b1=1;
var b2=1;
var b3=1;
//var imgb1="<img src='nostatusarrow.jpg' border='0'>";
//var imgb2="<img src='nostatusarrow.jpg' border='0'>";
//var imgb3="<img src='nostatusarrow.jpg' border='0'>";
var imgb1="<img src='Sort.png' border='0'>";
var imgb2="<img src='Sort.png' border='0'>";
var imgb3="<img src='Sort.png' border='0'>";
var lll2="";
var flagflag2="";
var input1="";
var input2="";
var input3="";
var input4="";
var input5=0;
var recordno=10;
var input6="";

function replaceAllCode1(str, a, b) {
	var result="";
	for(var i=0; i<str.length; i++) {
		var s = str.charAt(i);
		if(s==a) {
			s=b;
		}
		result+=s;
	}
	return result;
}


export const getBuildingAddr = (str, flag, lang1, n, currpage, key) =>
{
input1=replaceAllCode1(str, "'", "&#39;");
input2=flag;
input3=lang1;
input4=n;
input5=currpage;
input6=replaceAllCode1(key, "'", "&#39;");
lll2=lang1;
flagflag2=flag;
var a='';


if(n=='1') {
	b1++;
	b2=0;
	b3=0;
	a=b1;
}
if(n=='2') {
	b2++;
	b1=0;
	b3=0;
	a=b2;
}
if(n=='3') {
	b3++;
	b1=0;
	b2=0;
	a=b3;
}
if(n=='51') {
	b2=0;
	b3=0;
	a=b1;
}
if(n=='52') {
	b1=0;
	b3=0;
	a=b2;
}
if(n=='53') {
	b1=0;
	b2=0;
	a=b3;
}
if(n=='4' || n=='54') {
	b1=0;
	b2=0;
	b3=1;
	a=b3;
}


if(n=='1' || n=='51') {
	if(b1%2==0) {
		imgb1="<img src='descend.png' border='0'>";
	}
	else {
		imgb1="<img src='ascend.png' border='0'>";
	}
}
else {
	imgb1="<img src='Sort.png' border='0'>";
}
if(n=='2' || n=='52') {
	if(b2%2==0) {
		imgb2="<img src='descend.png' border='0'>";
	}
	else {
		imgb2="<img src='ascend.png' border='0'>";
	}
}
else {
	imgb2="<img src='Sort.png' border='0'>";
}
if(n=='3' || n=='53' || n=='4' || n=='54') {
	if(b3%2==0) {
		imgb3="<img src='descend.png' border='0'>";
	}
	else {
		imgb3="<img src='ascend.png' border='0'>";
	}
}
else {
	imgb3="<img src='Sort.png' border='0'>";
}
iseng1=flag;
if (str.length==0)
  {
  //document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
  return;
  }
xmlhttp31=GetXmlHttpObject31();
if (xmlhttp31==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var url=window.hkpostapi+"/correct_addressing/GetBuildingAddr.jsp";
url=url+"?building="+encodeURIComponent(str)+"&iseng="+flag+"&lang1="+lang1+"&n="+n+"&a="+a+"&currpage="+currpage+"&key="+encodeURIComponent(key)+"&img1="+encodeURIComponent(imgb1)+"&img2="+encodeURIComponent(imgb2)+"&img3="+encodeURIComponent(imgb3);
url=url+"&sid="+Math.random();
//xmlhttp31.onreadystatechange=stateChangedAtt31;
xmlhttp31.open("GET",url,false);
xmlhttp31.setRequestHeader("Accept-Charset","utf-8");
xmlhttp31.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp31.send(null);
stateChangedAtt31();
}

function stateChangedAtt31()
{
if (xmlhttp31.readyState==4)
  {
  	var result = xmlhttp31.responseText;
	var nextb="";
	var preb="";
	var gotot="";
	var recordstr="";
	
	if(lll2=="en_US") {
		preb="&lt;Prev&gt;";
		nextb="&lt;Next&gt;";
		gotot="Go to page: ";
		totalstr="Total";
		recordstr="Records";
	}
	else if(lll2=="zh_TW") {
		preb="&lt;上一頁&gt;";
		nextb="&lt;下一頁&gt;";
		gotot="跳到: ";
		totalstr="總共";
		recordstr="筆紀錄";
	}
	else {
		preb="&lt;上一页&gt;";
		nextb="&lt;下一页&gt;";
		gotot="跳到: ";
		totalstr="总共";
		recordstr="笔纪录";
	}
	
	if(result.indexOf("hihibyebye")==-1) {
		document.getElementById("buildingaddrselect").innerHTML=result;
		$("#buildingaddr").css("width", "");
		$("#rdsearchDIV3").show();
		var prep=parseInt(input5)-1;
		if(prep<1) {
			prep=1;
		}
		var nextp=parseInt(input5)+1;

		var totalp=1;
		if($("#bno").val()!=null) {
			totalp=Math.ceil($("#bno").val()/recordno);
		}

		if(nextp>=totalp) {
			nextp=totalp;
		}
		
		if(input4.length>1) {
			input4=input4.substr(1, 1);
		}
		
		if(totalp==0) {
			totalp=1;
		}
		

		var pagepart1="<a onMouseOver='this.style.cursor=\"pointer\"' onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", \"5"+input4+"\", "+prep+", \""+input6+"\");'><font color='#5555ff'><b>"+preb+"</b></font></a> "+input5+" / "+totalp+" <a onMouseOver='this.style.cursor=\"pointer\"' onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", \"5"+input4+"\", "+nextp+", \""+input6+"\");'><font color='#5555ff'><b>"+nextb+"</b></font></a> &nbsp; ";

		var pagepart2="<select id='selectbpage' onchange='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", \"5"+input4+"\", this.value, \""+input6+"\");'>";
		
		for(var j=1; j<=totalp; j++) {
			if(j==input5) {
				pagepart2+="<option value="+j+" selected>"+j+"</option>";
			}
			else {
				pagepart2+="<option value="+j+">"+j+"</option>";
			}
		}
		pagepart2+="</select>";
		
		document.getElementById("baddrpage").innerHTML=pagepart1+" &nbsp; "+gotot+pagepart2;
		document.getElementById("totalnoofrecordb").innerHTML="<b>"+totalstr+" "+$("#bno").val()+" "+recordstr+"</b>";
		if(iseng1=="true") {
			//document.getElementById("buildingaddrselectheader").innerHTML="<table style='border: 1px solid #AAAAAA; border-collapse: collapse; color: #000000;' width='760pt'><tr><th width='19pt' style='border: 1px solid black;'>&nbsp;</th><th onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", 3, 1, \""+input6+"\");' width='205pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>District </td><td valign='middle' style='border: 0px;'>"+imgb3+"</td></tr></table></th><th onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", 2, 1, \""+input6+"\");' width='230pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>Street </td><td valign='middle' style='border: 0px;'>"+imgb2+"</td></tr></table></th><th onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", 1, 1, \""+input6+"\");' width='255pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>Building </td><td valign='middle' style='border: 0px;'>"+imgb1+"</td></tr></table></th></tr></table>";
		}
		else {
			//document.getElementById("buildingaddrselectheader").innerHTML="<table style='border: 1px solid #AAAAAA; border-collapse: collapse; color: #000000;' width='760pt'><tr><th width='19pt' style='border: 1px solid black;'>&nbsp;</th><th onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", 3, 1, \""+input6+"\");' width='205pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;' ><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>地區 </td><td valign='middle' style='border: 0px;'>"+imgb3+"</td></tr></table></th><th onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", 2, 1, \""+input6+"\");' width='230pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>街道名稱 </td><td valign='middle' style='border: 0px;'>"+imgb2+"</td></tr></table></th><th onclick='getBuildingAddr(\""+input1+"\", \""+input2+"\", \""+input3+"\", 1, 1, \""+input6+"\");' width='255pt' style='border: 1px solid black;'><table border='0' style='background: #aa89ff;'><tr style='border: 0px;'><td valign='middle' style='border: 0px;'>樓宇名稱 </td><td valign='middle' style='border: 0px;'>"+imgb1+"</td></tr></table></th></tr></table>";
		}
		if($("#bno").val()=="1") {
			$("#buildingaddr1").attr("checked", true);
			getFloorByAddrValue3();
			getUnitByAddrValue3();
		}
		//getFloorByAddrValue3();
		//getUnitByAddrValue3();
		
		if(totalp==1 && $("#buildinginputkeyreal").val()=="") {
			hidekeyb();
		}
		
		//if(result.indexOf("hihibyebye")>-1) {
		if($("#bno").val()=="0") {
			//$("#rdsearchDIV3").hide();
			if(lll2=="en_US") {
				alert("No record find!");
			}
			else if(lll2=="zh_TW") {
				alert("找不到紀錄!");
			}
			else {
				alert("找不到纪录!");
			}
		}
		
		renderResultTable();
		
		//$("#buildingaddrselectheader").scrolltop();
		$("#rdsearch3").focus();
		
	}
  }
}

function GetXmlHttpObject31()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}