var xmlhttp10;

var ppp;

export const getPhaseV = (lang, lang1, estate, distr, street, streetno, phase) =>
{
ppp=phase;
//if (estate.length==0)
//  {
//  document.getElementById("phaseselect").innerHTML="<select id='phase' name='phase' style='width:505pt;'><option>Select Phase</option></select>";
//  return;
//  }

xmlhttp10=GetXmlHttpObject10();
if (xmlhttp10==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }

var url=window.hkpostapi+"/correct_addressing/GetPhase.jsp";
url=url+"?estate="+encodeURIComponent(estate)+"&district="+distr+"&street="+encodeURIComponent(street)+"&streetno="+streetno+"&lang1="+lang1;

url=url+"&sid="+Math.random();
//xmlhttp10.onreadystatechange=stateChangedAtt10;
xmlhttp10.open("GET",url,false);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp10.send(null);
stateChangedAtt10();
}

function stateChangedAtt10()
{
if (xmlhttp10.readyState==4)
  {
	document.getElementById("phaseselect").innerHTML=xmlhttp10.responseText;
	$("#phase").css("width", "");
	$("#phase").attr("class","form-control selectpicker");
	$("#phase").attr("onchange","");
	if($('select#phase option').length==1) {
		$("#phase").attr('disabled', 'disabled');
	}
	else {
		$("#phase").removeAttr('disabled');
	}
	if($("#phase option[value='"+ppp+"']").length !== 0) {
		$("#phase").val(ppp);
	}
	else {
		$("#phase").val('');
	}
	
	if($('select#phase option').length==2) {
		//$("#phase").prop('selectedIndex',1);
		//getStrList();getStreetNoValue1();getBfromE();getEfromStreet();getFloorValue();getUnitValue();
	}
	$("#phase").select2({
      theme: 'bootstrap4'
    });
		
}
}

function GetXmlHttpObject10()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

