var xmlhttp25

export const getFloorByAddr = (str, flag, lang1) =>
{
	var data = str.split("|");
	var loc_id=data[0];
	var sub_loc_no=data[1];
//if (estate.length==0)
//  {
//  document.getElementById("floorselect").innerHTML="<select id='floor' name='floor'><option>Select Floor</option></select>";
//  return;
//  }

xmlhttp25=GetXmlHttpObject25();
if (xmlhttp25==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }

var url=window.hkpostapi+"/correct_addressing/GetFloorByAddr.jsp";
url=url+"?loc_id="+loc_id+"&sub_loc_no="+sub_loc_no+"&iseng="+flag+"&lang1="+lang1;

url=url+"&sid="+Math.random();
xmlhttp25.onreadystatechange=stateChangedAtt25;
xmlhttp25.open("GET",url,true);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp25.send(null);

}

function stateChangedAtt25()
{
if (xmlhttp25.readyState==4)
  {
	var floorselect1 = xmlhttp25.responseText;
	//alert("F1:"+floorselect1);
	if(floorselect1.indexOf("Start111")>0) {
		floorselect1start=floorselect1.indexOf("Start111")+8;
		floorselect1end=floorselect1.indexOf("End111");
		floorselectval=floorselect1.substring(floorselect1start, floorselect1end);
		//alert(floorselectval);
		floorselectvalarr = floorselectval.split("||");
		floorlocid=floorselectvalarr[0];
		floorsublocno=floorselectvalarr[1];
		floorlocnum=floorselectvalarr[2];
		//alert(floorlocid);
		//alert(floorsublocno);
		$("#def_addr_flag").val("Y");
		$("#loc_id1").val(floorlocid);
		$("#sub_loc_no1").val(floorsublocno);
		$("#locnum").val(floorlocnum);
		//alert(floorlocid+"!!"+floorsublocno+"!!"+floorlocnum);
		document.getElementById("flooraddrselect").innerHTML="<select id='floor' name='floor'><option value=''>Select Floor</option></select>";
	}
	else {
		$("#def_addr_flag").val("N");
		$("#loc_id1").val("");
		$("#sub_loc_no1").val("");
		$("#locnum").val(1);
		document.getElementById("flooraddrselect").innerHTML=floorselect1;
	}
	$("#flooraddr").css("width", "");
	//alert("F:"+floorselect1);
	
	if($('select#floor option').length==1) {
		$("#floor").attr('disabled', 'disabled');
	}
	else {
		$("#floor").removeAttr('disabled');
	}
}
}

function GetXmlHttpObject25()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

