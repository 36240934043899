var xmlhttp7

export const getStreetBuilding = (lang, lang1, type) =>
{
//if (str.length==0)
//  {
  //document.getElementById("buildingselect").innerHTML="<select id='building' name='building'><option>Select Building</option></select>";
//  return;
//  }
xmlhttp7=GetXmlHttpObject7();
if (xmlhttp7==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }
var streetnoarr = $("#streetno").val().split("-");

var streetfr = "";
var streetto = "";
if(streetnoarr[0]!=null) {
	streetfr = streetnoarr[0];
}
if(streetnoarr.length>1 && streetnoarr[1]!=null) {
	streetto = streetnoarr[1];
}

if(type=="") {
	if($("#street").val()=="") {
		type="E";
	}
	else {
		type="S";
	}
}
if($("#strtype").val()=="Building") {
	type="B";
}
//alert("streetfr: "+streetfr);
//alert("streetto: "+streetto);
//alert("B:"+b);

var url=window.hkpostapi+"/correct_addressing/GetStreetBuilding.jsp";
url=url+"?street="+encodeURI($('#street').val())+"&streetnofr="+streetfr+"&streetnoto="+streetto+"&lang="+lang+"&lang1="+lang1+"&building="+encodeURI($('#building').val())+"&type="+type+"&estate="+encodeURI($('#estate').val());

url=url+"&sid="+Math.random();
xmlhttp7.onreadystatechange=stateChangedAtt7;
xmlhttp7.open("GET",url,true);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp7.send(null);
}

function stateChangedAtt7()
{
if (xmlhttp7.readyState==4)
  {
	//if($("#street").val()=="" && $("#estate").val()=="") {
	//	$("#buildingDIV").hide();
	//	$("#buildingDIV").val("");
	//	valuehide("#blockno");
	//}
	var allData = xmlhttp7.responseText.replace(/[\r\n]*/g, "").split("|");
	//alert("hihi:"+xmlhttp7.responseText.replace(/[\r\n]*/g, "")+":hihi");
	var head = allData[0];
	//alert("head:"+head);
    var allSearch = allData[1];
	//alert("allSearch:" +allSearch);
	var search = allSearch.split(",");
	var availableTag1s = searchhead(search, head).split(",");
	if(xmlhttp7.responseText.replace(/[\r\n]*/g, "")!="|" && ($("#street").val()!="" || $("#estate").val()!="")) {
		$("#buildingDIV").show();
	}
	else {
		valuehide("#building");
		valuehide("#blockno");
	}
	
	//for(var i=0; i<availableTag1s.length; i++) {
	//	alert(availableTag1s[i]);
	//}

	$( "#building" ).autocomplete({

	  source: availableTag1s,
	  focus: function(event, ui) {
		getB();
	  }

	});
}
}

function GetXmlHttpObject7()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

