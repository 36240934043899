import {structerror, redselect, unitdesc1choose, getunitfunction} from "src/address_selector/utils"


var xmlhttp18

export const getUnit = (strno, street, estate, phase, building, district, floor, lang1) =>
{

//if (estate.length==0)
//  {
//  document.getElementById("unitselect").innerHTML="<select id='unit' name='unit'><option>Select Unit</option></select>";
//  return;
//  }

xmlhttp18=GetXmlHttpObject18();
if (xmlhttp18==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }

var url=window.hkpostapi+"/correct_addressing/GetUnit.jsp";
url=url+"?strno="+encodeURIComponent(strno)+"&street="+encodeURIComponent(street)+"&estate="+encodeURIComponent(estate)+"&phase="+encodeURIComponent(phase)+"&building="+encodeURIComponent(building)+"&district="+encodeURIComponent(district)+"&floor="+encodeURIComponent(floor)+"&lang1="+encodeURIComponent(lang1);

url=url+"&sid="+Math.random();
xmlhttp18.onreadystatechange=stateChangedAtt18;
xmlhttp18.open("GET",url,true);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp18.send(null);

}

function stateChangedAtt18()
{
if (xmlhttp18.readyState==4)
  {
	document.getElementById("unitselect").innerHTML=xmlhttp18.responseText;
	$("#unit").css("width", "");
	$("#unit").attr("class","form-control selectpicker");
    $("#unit").attr("onchange","");
	$("#unit").change(() => {
		getunitfunction();
	});
	$("#unitdesc1").attr("onchange","");
	$("#unitdesc1").change(() => {
		unitdesc1choose();
	});
	 if($('select#unit option').length<=2) {
		$("#unit").attr('disabled', 'disabled'); 
		//alert("LocnumU:"+$("#locnum").val());
		if(parseInt($("#locnum").val())==1) {
			$("#unit1box").show();
		}
		else {
			$("#unit1inpute").val("");
			$("#unitdesc1").val("");
			$("#unit1box").hide();
		}
	}
	else {
		$("#unit").removeAttr('disabled');	
		$("#unit1inpute").val("");
		$("#unitdesc1").val("");
		$("#unit1box").hide();
	}
	
	if(($('select#floor option').length==2 || $('select#floor option').length==3) && $('select#unit option').length==3) {
		$("#unit").prop('selectedIndex',1);
	}
	else if($("#floor").val()=="" && $('select#floor option').length>2) {
		$("#unit").attr('disabled', 'disabled');
	}
	
	if(structerror) {
		redselect('#street');
		redselect('#streetno');
		redselect('#estate');
		redselect('#phase');
		redselect('#building');
		redselect('#floor');
		redselect('#unit');
	}
	$("#unit").select2({
      theme: 'bootstrap4'
    });
	//if($("#floor").val().substr(0, 9)=="notinlist") {
	//	$("#unit").val($("#unit option:last-child").val());
	//}
}
}

function GetXmlHttpObject18()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

