var xmlhttp45

export const getFloorByAddr2 = (str, flag, lang1) =>
{
	var data = str.split("|");
	var loc_id=data[0];
	var sub_loc_no=data[1];
//if (estate.length==0)
//  {
//  document.getElementById("floorselect").innerHTML="<select id='floor' name='floor'><option>Select Floor</option></select>";
//  return;
//  }

xmlhttp45=GetXmlHttpObject45();
if (xmlhttp45==null)
  {
  alert ("Your browser does not support XMLHTTP!");
  return;
  }

var url=window.hkpostapi+"/correct_addressing/GetFloorByAddr2.jsp";
url=url+"?loc_id="+loc_id+"&sub_loc_no="+sub_loc_no+"&iseng="+flag+"&lang1="+lang1;

url=url+"&sid="+Math.random();
xmlhttp45.onreadystatechange=stateChangedAtt45;
xmlhttp45.open("GET",url,false);
//xmlhttp6.setRequestHeader("Accept-Charset","utf-8");
//xmlhttp6.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
xmlhttp45.send(null);

}

function stateChangedAtt45()
{
if (xmlhttp45.readyState==4)
  {
	var floorselect1 = xmlhttp45.responseText;
	//alert("F1:"+floorselect1);
	if(floorselect1.indexOf("Start111")==0) {
		floorselect1start=floorselect1.indexOf("Start111")+8;
		floorselect1end=floorselect1.indexOf("End111");
		floorselectval=floorselect1.substring(floorselect1start, floorselect1end);
		//alert(floorselectval);
		floorselectvalarr = floorselectval.split("||");
		floorlocid=floorselectvalarr[0];
		floorsublocno=floorselectvalarr[1];
		floorlocnum=floorselectvalarr[2];
		//alert(floorlocid);
		//alert(floorsublocno);
		$("#def_addr_flag2").val("Y");
		$("#loc_id12").val(floorlocid);
		$("#sub_loc_no12").val(floorsublocno);
		$("#locnum2").val(floorlocnum);
		//alert(floorlocid+"!!"+floorsublocno+"!!"+floorlocnum);
		document.getElementById("flooraddrselect2").innerHTML="<select id='floor2' name='floor2'><option value=''>Select Floor</option></select>";
	}
	else if(floorselect1.indexOf("Start111")>0) {
		floorselect1start=floorselect1.indexOf("Start111")+8;
		floorselect1end=floorselect1.indexOf("End111");
		floorselectval=floorselect1.substring(floorselect1start, floorselect1end);
		//alert(floorselectval);
		floorselectvalarr = floorselectval.split("||");
		floorlocid=floorselectvalarr[0];
		floorsublocno=floorselectvalarr[1];
		floorlocnum=floorselectvalarr[2];
		//alert(floorlocid);
		//alert(floorsublocno);
		$("#loc_id12").val(floorlocid);
		$("#sub_loc_no12").val(floorsublocno);
		$("#locnum2").val(floorlocnum);
		$("#def_addr_flag2").val("N");
		document.getElementById("flooraddrselect2").innerHTML=floorselect1.substring(0, floorselect1.indexOf("Start111"));
	}
	$("#flooraddr").css("width", "");
	//alert("F:"+floorselect1);
	
	if($('select#floor2 option').length<=2) {
		$("#floor2").attr('disabled', 'disabled');
		$("#floor2box").show();
	}
	else {
		$("#floor2").removeAttr('disabled');
		$("#floor2inpute").val("");
		$("#flrdesc2").val("");
		$("#floor2box").hide();
	}
	
	if($('select#floor2 option').length==3) {
		$("#floor2").prop('selectedIndex',1);
	}

}
}

function GetXmlHttpObject45()
{
if (window.XMLHttpRequest)
  {
  // code for IE7+, Firefox, Chrome, Opera, Safari
  return new XMLHttpRequest();
  }
if (window.ActiveXObject)
  {
  // code for IE6, IE5
  return new ActiveXObject("Microsoft.XMLHTTP");
  }
return null;
}

